import React, { useCallback, useMemo, useState } from "react";
import Drawer from "devextreme-react/drawer";

import "./SimpleLayout.scss";
import Toolbar, { Item } from "devextreme-react/toolbar";
import NavigationList from "./NavigationList";

function SimpleLayout({ children }) {
  const [isOpened, setIsOpened] = useState(false);

  const buttonOptions = useMemo(() => {
    return {
      icon: "menu",
      onClick: () => {
        setIsOpened((old) => !old);
      },
    };
  }, []);

  const renderList = useCallback(() => {
    const stateHandler = (newState) => setIsOpened(newState);
    return <NavigationList stateHandler={stateHandler} />;
  }, []);

  return (
    <>
      <header className="header-component">
        <Toolbar className={"header-toolbar"}>
          <Item
            widget="dxButton"
            options={buttonOptions}
            location="before"
            cssClass={"menu-button"}
          />
          <Item
            location={"before"}
            cssClass={"header-title"}
            text="Gerenciar Inscrição"
            // visible={!!title}
          />
        </Toolbar>
      </header>
      <Drawer
        minSize={36}
        render={renderList}
        opened={isOpened}
        revealMode="expand"
        openedStateMode="shrink"
      >
        <div id="content" className="dx-theme-background-color">
          {children}
        </div>
      </Drawer>
    </>
  );
}

export default SimpleLayout;
