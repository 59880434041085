import React, { useCallback, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import List from "devextreme-react/list";

import { routes } from "../../routes";

NavigationList.propTypes = {
  stateHandler: PropTypes.func.isRequired,
};

function NavigationList({ stateHandler }) {
  const user = useSelector((state) => state.auth.user);
  const navigation = useRef(routes[user.role]);

  const closeDrawer = () => {
    stateHandler(false);
  };

  const items = useMemo(
    () => navigation?.current.filter((item) => !item.routerOnly),
    []
  );

  const renderItem = useCallback((data) => {
    return (
      <div>
        <Link to={data.path}>
          <div>
            <div className="dx-list-item-icon-container">
              <i className={`dx-icon dx-list-item-icon dx-icon-${data.icon}`} />
            </div>
            <span className="list-item-text">{data.label}</span>
          </div>
        </Link>
      </div>
    );
  }, []);

  return (
    <List
      items={items}
      width={200}
      selectionMode="single"
      onSelectionChanged={closeDrawer}
      itemRender={renderItem}
      className="panel-list dx-theme-accent-as-background-color"
    />
  );
}

export default NavigationList;
