import React, { useEffect, useState, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { routes } from "./routes";

function Navigation({ role }) {
  const [userRoutes, setUserRoles] = useState(routes[role]);

  useEffect(() => {
    if (role) {
      setUserRoles(routes[role]);
    } else {
      setUserRoles([]);
    }
  }, [role]);

  return (
    <Suspense fallback={<>Loading...</>}>
      <Routes>
        {userRoutes?.map(({ path, element: Component }) => (
          <Route key={path} path={path} element={<Component />} />
        ))}
        <Route
          path="/*"
          element={<Navigate to={userRoutes[0]?.path} replace />}
        />
      </Routes>
    </Suspense>
  );
}

export default Navigation;
