import { lazy } from "react";

const Login = lazy(() => import("./pages/auth/Login"));
const Register = lazy(() => import("./pages/auth/Register"));

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Users = lazy(() => import("./pages/users"));
const Campaigns = lazy(() => import("./pages/campaigns"));
const Partners = lazy(() => import("./pages/partners"));
const Customers = lazy(() => import("./pages/customers"));
const Emails = lazy(() => import("./pages/emails"));
const Reports = lazy(() => import("./pages/reports"));
const Bases = lazy(() => import("./pages/bases"));
const UnsubscribeLinks = lazy(() => import("./pages/unsubscribeLinks"));

const RegisteredUser = lazy(() => import("./pages/user"));

export const routes = {
  user: [{ path: "/", element: RegisteredUser, label: "" }],
  public: [
    { path: "/login", element: Login, label: "Login" },
    { path: "/register", element: Register, label: "Register" },
  ],
  adm: [
    {
      id: 1,
      path: "/dashboard",
      element: Dashboard,
      label: "Dashboard",
      icon: "home",
    },
    { id: 2, path: "/users", element: Users, label: "Users", icon: "group" },
    {
      id: 3,
      path: "/customers",
      element: Customers,
      label: "Customers",
      icon: "check",
    },
    {
      id: 4,
      path: "/partners",
      element: Partners,
      label: "Partners",
      icon: "percent",
    },
    {
      id: 5,
      path: "/campaigns",
      element: Campaigns,
      label: "Campaigns",
      icon: "doc",
    },
    { id: 6, path: "/bases", element: Bases, label: "Bases", icon: "coffee" },
    {
      id: 7,
      path: "/emails",
      element: Emails,
      label: "Emails",
      icon: "mention",
    },
    {
      id: 9,
      path: "/unsubscribe-links",
      element: UnsubscribeLinks,
      label: "Unsubscribe Links",
      icon: "link",
    },
    {
      id: 8,
      path: "/reports",
      element: Reports,
      label: "Reports",
      icon: "xlsfile",
    },
  ],
  customer: [
    {
      id: 1,
      path: "/reports",
      element: Reports,
      label: "Reports",
      icon: "xlsfile",
    },
  ],
  partner: [
    {
      id: 7,
      path: "/emails",
      element: Emails,
      label: "Emails",
      icon: "mention",
    },
    {
      id: 9,
      path: "/unsubscribe-links",
      element: UnsubscribeLinks,
      label: "Unsubscribe Links",
      icon: "",
    },
  ],
};
