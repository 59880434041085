import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BrowserRouter as Router } from "react-router-dom";
import "devextreme/ui/text_box";
import { locale, loadMessages } from "devextreme/localization";
import ptMessages from "devextreme/localization/messages/pt.json";

import "devextreme/dist/css/dx.light.css";
import Navigation from "./Navigation";

import { setUser } from "./features/auth/slice";
import SimpleLauout from "./layout/SimpleLauout";

import "./App.css";

function App() {
  loadMessages(ptMessages);
  locale(navigator.language);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    let currentUser;
    try {
      currentUser = JSON.parse(localStorage.getItem("user") || null);
    } catch (e) {
      currentUser = null;
    }

    if (currentUser) dispatch(setUser(currentUser));
  }, [dispatch]);

  console.log(user);

  const getView = (role) => {
    switch (role) {
      case "user":
        return <Navigation role={"user"} />;
      case "adm":
      case "customer":
      case "partner":
        return (
          <SimpleLauout title="OptOut">
            <Navigation role={role} />
          </SimpleLauout>
        );
      default:
        return <Navigation role={"public"} />;
    }
  };

  return <Router>{getView(user?.role)}</Router>;
}

export default App;
