import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
// import jwt from "jsonwebtoken";

const { REACT_APP_API_URL } = process.env;

export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();

      const token = auth?.token;
      if (token) {
        // const decodedToken = jwt.decode(token, { complete: true });
        // const dateNow = new Date();
        //
        // if (dateNow.getTime() > decodedToken.exp) {
        //   headers.set("authorization", `Bearer ${token}`);
        // }

        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  // tagTypes: [],
  endpoints: (builder) => ({}),
});
