import { configureStore } from "@reduxjs/toolkit";
// import { combineReducers } from "redux";
import { setupListeners } from "@reduxjs/toolkit/query/react";
//
// import { persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import thunk from "redux-thunk";

import { appApi } from "./api/appApi";
import authReducer from "../features/auth/slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [appApi.reducerPath]: appApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(appApi.middleware),
});

setupListeners(store.dispatch);

// const reducers = combineReducers({
//   // API features
//   [appApi.reducerPath]: appApi,
// });
//
// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: [],
// };
//
// const persistedReducer = persistReducer(persistConfig, reducers);
//
// export const store = configureStore({
//   reducer: persistedReducer,
//   devTools: process.env.NODE_ENV !== "production",
//   middleware: [thunk, appApi.middleware],
// });
//
// setupListeners(store.dispatch);

export default store;
